import { useTranslations } from 'next-intl';

import ListingProductSkeleton from '@/components/listing-product-skeleton';
import { RcLink, RcLinkVariant } from '@/components/rc-link';

export function RedeemAgainBannerSkeleton() {
  const t = useTranslations('redeemAgainBannerSkeleton');

  const redeemAgainText = t('redeemAgain');
  const viewAllRedemptionsText = t('viewAllRedemptions');

  return (
    <div className="flex flex-col gap-6 container-responsive">
      <div className="flex w-full items-center justify-between gap-4 lg:justify-start">
        <p className="font-heading text-base-bold md:text-xl-bold">
          {redeemAgainText}
        </p>
        <RcLink
          href="/rewards-summary/my-rewards"
          variant={RcLinkVariant.SECONDARY}
          className="text-sm text-secondary lg:text-base"
        >
          {viewAllRedemptionsText}
        </RcLink>
      </div>

      <div className="grid grid-cols-3 gap-6">
        {[1, 2, 3].map((item) => (
          <ListingProductSkeleton
            key={item}
            className="h-[72px] overflow-hidden border-0"
          />
        ))}
      </div>
    </div>
  );
}

'use client';

import { useTranslations } from 'next-intl';

import { Icon } from '@/components/icon';
import { RcLink } from '@/components/rc-link';
import { Button } from '@/design-system-components/button/button';
import ImageWithFallback from '@/design-system-components/default-image/image-with-fallback';
import { useDateTimeFormatter } from '@/hooks/utils/use-date-time-formatter';
import { CampaignSchema } from '@/schema/campaigns/campaign.schema';
import { CAMPAIGN_CARD_DATE_TIME_FORMAT_OPTION } from '@/utils/date-time-format-options';
import { generateImageUrl } from '@/utils/images-urls';
import { CampaignTermsAndConditionsModal } from './terms-and-conditions-modal';

interface Props {
  campaign: CampaignSchema;
  index?: number;
}

export function CampaignCard({ campaign, index }: Props) {
  const t = useTranslations('campaignCard');

  const { campaignCardDateFormatter } = useDateTimeFormatter([
    CAMPAIGN_CARD_DATE_TIME_FORMAT_OPTION,
  ]);

  const dataGtm = {
    event: 'homepage_campaign_item_click',
    ranking: index,
  };
  const endsText = campaign.qualifyingEndTime
    ? t('ends_date', {
        date: campaignCardDateFormatter.format(
          new Date(campaign.qualifyingEndTime),
        ),
      })
    : '';
  const termsAndConditionsText = t('termsAndConditions');

  return (
    <div className="flex h-full flex-col overflow-hidden rounded bg-white lg:flex-row">
      <div className="order-2 flex h-full flex-col justify-between border border-neutral-400 p-4 max-lg:rounded-b max-lg:border-t-0 lg:order-1 lg:flex-grow lg:rounded-l lg:border-r-0">
        <div>
          <h2 className="mb-2 font-bold" data-testid="campaign-title">
            {campaign.title}
          </h2>

          <p className="lg:line-clamp-2" data-testid="campaign-description">
            {campaign.description}
          </p>

          {campaign.qualifyingEndTime ? (
            <p className="my-2 text-hint" data-testid="campaign-end-date">
              {endsText}
            </p>
          ) : null}

          {campaign.termsAndConditions ? (
            <CampaignTermsAndConditionsModal
              terms={campaign.termsAndConditions}
            >
              <Button
                variant="noBackground"
                className="group my-2 p-0 font-normal text-secondary"
                data-testid="campaign-terms-and-conditions-button"
              >
                {termsAndConditionsText}
                <Icon
                  name="angle-right"
                  className="translate-x-1 text-secondary transition-transform group-hover:translate-x-2 group-hover:text-neutral-400"
                />
              </Button>
            </CampaignTermsAndConditionsModal>
          ) : null}
        </div>
        {campaign.ctaButton && campaign.ctaUrl ? (
          <div>
            <RcLink
              href={campaign.ctaUrl}
              target={campaign.ctaUrl.startsWith('http') ? '_blank' : undefined}
              className="gtm:homepage_campaign mt-4"
              data-testid="campaign-cta-button"
              data-gtm={JSON.stringify(dataGtm)}
            >
              <Button className="mt-4 rounded-lg px-4 py-2">
                {campaign.ctaText}
              </Button>
            </RcLink>
          </div>
        ) : null}
      </div>
      <div className="order-1 shrink-0 lg:order-2">
        <ImageWithFallback
          className="h-full w-full object-cover leading-none lg:w-[456px]"
          src={generateImageUrl({ url: campaign.coverImageUrl })}
          ignoreError
          alt=""
        >
          <div className="h-full w-full bg-neutral-300 object-cover lg:w-[456px]" />
        </ImageWithFallback>
      </div>
    </div>
  );
}

import { NestedKeyOf, NestedValueOf } from 'next-intl';

import {
  OfferActivationFilter,
  OfferCategoryFilter,
  OfferChannelFilter,
  OfferSortType,
  OfferTypeFilter,
} from '@/app/[locale]/products/offers/type';

export interface OfferFilter<Value = string, Key extends string = string> {
  value: Value;
  translationKey: Key;
}

export const offerChannelOptions: OfferFilter<
  OfferChannelFilter,
  Exclude<
    NestedKeyOf<
      Pick<NestedValueOf<IntlMessages, 'offerFilters'>, 'channelOptions'>
    >,
    'channelOptions'
  >
>[] = [
  { value: OfferChannelFilter.All, translationKey: 'channelOptions.all' },
  { value: OfferChannelFilter.Online, translationKey: 'channelOptions.online' },
  {
    value: OfferChannelFilter.Offline,
    translationKey: 'channelOptions.inStore',
  },
];

/**
 * @deprecated Redundant constant. To be removed later.
 */
export const offerTypeOptions: OfferFilter<OfferTypeFilter>[] = [
  { value: OfferTypeFilter.All, translationKey: 'All' },
  {
    value: OfferTypeFilter.CardLinkedOffer,
    translationKey: 'Card-linked Offers',
  },
  { value: OfferTypeFilter.AffiliateOffer, translationKey: 'Affiliate Offers' },
];

export const offerActivationOptions: OfferFilter<
  OfferActivationFilter,
  Exclude<
    NestedKeyOf<
      Pick<NestedValueOf<IntlMessages, 'offerFilters'>, 'activationOptions'>
    >,
    'activationOptions'
  >
>[] = [
  { value: 'all', translationKey: 'activationOptions.all' },
  { value: 'required', translationKey: 'activationOptions.required' },
  { value: 'not-required', translationKey: 'activationOptions.notRequired' },
];

export const offerCategoryOptions: OfferFilter<
  OfferCategoryFilter,
  Exclude<
    NestedKeyOf<
      Pick<NestedValueOf<IntlMessages, 'offerFilters'>, 'categoryOptions'>
    >,
    'categoryOptions'
  >
>[] = [
  { value: OfferCategoryFilter.All, translationKey: 'categoryOptions.all' },
  {
    value: OfferCategoryFilter.FoodAndDrink,
    translationKey: 'categoryOptions.foodAndDrink',
  },
  {
    value: OfferCategoryFilter.Technology,
    translationKey: 'categoryOptions.technology',
  },
  {
    value: OfferCategoryFilter.HealthAndBeauty,
    translationKey: 'categoryOptions.healthAndBeauty',
  },
  {
    value: OfferCategoryFilter.Lifestyle,
    translationKey: 'categoryOptions.lifestyle',
  },
  {
    value: OfferCategoryFilter.Retail,
    translationKey: 'categoryOptions.retail',
  },
  {
    value: OfferCategoryFilter.Travel,
    translationKey: 'categoryOptions.travel',
  },
  {
    value: OfferCategoryFilter.Others,
    translationKey: 'categoryOptions.others',
  },
];

export const offerSortOptions: OfferFilter<
  OfferSortType,
  Exclude<
    NestedKeyOf<
      Pick<NestedValueOf<IntlMessages, 'offerFilters'>, 'sortOptions'>
    >,
    'sortOptions'
  >
>[] = [
  { value: OfferSortType.Distance, translationKey: 'sortOptions.distance' },
  {
    value: OfferSortType.Recommended,
    translationKey: 'sortOptions.recommended',
  },
  {
    value: OfferSortType.BrandAscending,
    translationKey: 'sortOptions.brandAscending',
  },
  {
    value: OfferSortType.BrandDescending,
    translationKey: 'sortOptions.brandDescending',
  },
];

export const getDefaultOffersParams = (offerType = OfferTypeFilter.All) => ({
  activation: offerActivationOptions[0].value,
  category: OfferCategoryFilter.All,
  page: 1,
  pageSize: 24,
  redemptionMethod: OfferChannelFilter.Online,
  search: '',
  sort:
    offerType === OfferTypeFilter.All
      ? OfferSortType.BrandAscending
      : OfferSortType.Recommended,
  location: 'New York City, New York, United States',
  lat: '40.7127753', // New York City
  lon: '-74.0059728', // New York City
});

export const getDefaultOffersURLSearchParams = (
  offerType = OfferTypeFilter.All,
) =>
  new URLSearchParams(
    JSON.parse(JSON.stringify(getDefaultOffersParams(offerType))),
  );

export const offersFilterOptionFallback = <T>(
  category: T,
  categories: OfferFilter<T>[],
): T =>
  categories.some(({ value }) => value === category)
    ? category
    : categories[0].value;

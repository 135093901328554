'use client';

import { useTranslations } from 'next-intl';

import { useHermes } from '@/auth/use-hermes';
import { Container } from '@/components/container';
import { PointsBalanceSkeleton } from '@/components/header/header-v2';
import { Icon } from '@/components/icon';
import { RcLink, RcLinkVariant } from '@/components/rc-link';
import { QuickLinksV2Config } from '@/config-schema/home-page';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { isPointsActivityEnabled } from '@/features/rewards-summary/shared/utils';
import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';
import { useFeatureConfig } from '@/hooks/config/use-feature-config';
import { useHeaderLinks } from '@/hooks/config/use-header-links';
import { usePointsAccounts } from '@/hooks/points-accounts/use-points-accounts';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';
import { cn } from '@/utils/tailwind';
import { MobileQuickLink } from './mobile-quick-link';
import { QuickLinkV2 } from './quick-links-v2';

interface QuickLinksProps {
  config: QuickLinksV2Config['config'];
  features: RewardsCentralConfiguration['application']['features'];
  navigationConfiguration: RewardsCentralConfiguration['application']['components']['navigation'];
}

export function HeroBannerAndQuickLinksV2({
  config,
  features,
  navigationConfiguration,
}: QuickLinksProps) {
  const { user } = useHermes();
  const pointsFormatter = usePointsFormatter();
  const { data: pointsAccount, isLoading: isPointsBalanceLoading } =
    usePointsAccounts();
  const t = useTranslations('homePageHeroBannerAndQuickLinksV2');
  const tenantHubConfig = useTenantHubConfig();
  const { headerLinks } = useHeaderLinks(features, navigationConfiguration);

  const hideQuickLinks = headerLinks.length <= 1;

  const featureConfig = useFeatureConfig('rewardsSummary');
  const isPointsActivityEnabledFlag = isPointsActivityEnabled(featureConfig);

  const oneLinePointsBalance =
    (pointsFormatter(pointsAccount?.pointsBalance) || '').length < 15;
  const welcomeBackText = user?.firstName
    ? t('welcomeBack_username', {
        username: user.firstName,
      })
    : '';
  const youHaveText = t('youHave');
  const pointsActivityText = t('pointsActivity');

  return (
    <>
      <div
        style={{
          backgroundColor:
            tenantHubConfig?.header_color || config.backgroundColor,
          color: tenantHubConfig?.header_font_color || config.textColor,
        }}
      >
        <Container>
          <h1 className="mb-0 pt-4 font-heading text-xl-bold md:text-3xl-bold lg:pt-10">
            {welcomeBackText}
          </h1>
        </Container>

        <Container className={cn('hidden lg:block')}>
          <div
            className={cn(
              'w-full translate-y-1/2 px-4',
              'flex items-center justify-start',
              'rounded-custom bg-white px-8 py-4 text-primary shadow-elevation-medium',
              { 'w-fit': hideQuickLinks },
            )}
          >
            <div className="flex shrink-0 items-center justify-between">
              <div className="flex flex-row">
                <div className="pr-4 text-neutral-600">{youHaveText}</div>

                {isPointsBalanceLoading ? (
                  <PointsBalanceSkeleton className="mr-6" />
                ) : (
                  <div
                    data-testid="quick-link-points-balance"
                    className="ml-auto mr-6 flex items-center gap-2 font-bold text-neutral-900 lg:ml-0"
                  >
                    <Icon name="gem" />
                    <span>{pointsFormatter(pointsAccount?.pointsBalance)}</span>
                  </div>
                )}
              </div>

              <RcLink
                href="/rewards-summary/points-activity"
                variant={RcLinkVariant.SECONDARY}
                className={cn(
                  'flex items-center justify-center gap-2 font-bold text-secondary',
                  'gtm:homepage_v2_quicklink_pa',
                  {
                    hidden: !isPointsActivityEnabledFlag,
                  },
                )}
              >
                {pointsActivityText}

                <Icon name="chevron-right" />
              </RcLink>
            </div>

            <div
              className={cn(
                'mx-8 h-[44px] w-[1px] border-l !text-neutral-900',
                { hidden: hideQuickLinks },
              )}
              style={{ opacity: 0.25 }}
            />

            <QuickLinkV2
              headerLinks={headerLinks}
              className={cn({ hidden: hideQuickLinks })}
            />
          </div>
        </Container>

        <Container className={cn('lg:hidden', { 'pb-4': hideQuickLinks })}>
          <RcLink
            href="/rewards-summary/points-activity"
            onClick={(e) => {
              if (!isPointsActivityEnabledFlag) e.preventDefault();
            }}
            variant={RcLinkVariant.SECONDARY}
            className={cn(
              'w-full translate-y-1/2 px-4',
              'flex items-center justify-start',
              'rounded-custom bg-white px-8 py-4 text-primary shadow-elevation-medium',
            )}
          >
            <div className="flex w-full items-center justify-between">
              <div
                className={cn(
                  'flex',
                  { 'flex-row': oneLinePointsBalance },
                  { 'flex-col': !oneLinePointsBalance },
                )}
              >
                <div className="pr-4 text-neutral-600">{youHaveText}</div>

                {isPointsBalanceLoading ? (
                  <PointsBalanceSkeleton />
                ) : (
                  <div
                    data-testid="header-points-balance"
                    className="ml-auto flex items-center gap-2 pr-6 font-bold text-neutral-900"
                  >
                    <Icon name="gem" />
                    <span>{pointsFormatter(pointsAccount?.pointsBalance)}</span>
                  </div>
                )}
              </div>

              <Icon
                name="chevron-right"
                className={cn('text-neutral-700', {
                  hidden: !isPointsActivityEnabledFlag,
                })}
              />
            </div>
          </RcLink>
        </Container>
      </div>

      <MobileQuickLink
        headerLinks={headerLinks}
        className={cn('pt-4 lg:hidden', { hidden: hideQuickLinks })}
      />
    </>
  );
}

'use client';
import { useEffect } from 'react';
import {
  AmplitudeTrackEventFunction,
  useTrackAmplitudeEvent,
} from './use-track-amplitude-event';

export const useTrackAmplitudeEventOnPageLoad: AmplitudeTrackEventFunction = (
  eventName,
  eventProperties,
) => {
  const trackAmplitudeEvent = useTrackAmplitudeEvent();

  useEffect(() => {
    trackAmplitudeEvent(eventName, eventProperties);
  }, []);
};

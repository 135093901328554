'use client';

import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import { HomepageProductsCarousel } from '@/components/homepage-products-carousel/homepage-products-carousel';
import { Icon } from '@/components/icon';
import { RcLink, RcLinkVariant } from '@/components/rc-link';
import ImageWithFallback from '@/design-system-components/default-image/image-with-fallback';
import { useRedeemAgainItems } from '@/hooks/order_items/use-redeem-again-items';
import useProduct, {
  OrderItemTypeMapper,
  ProductType,
} from '@/hooks/products/use-product';
import { useRouter } from '@/i18n/navigation';
import { orderItemTypeToTypeInPath } from '@/schema/order/common';
import { RedeemAgainOrderItem } from '@/schema/order/order-item';
import { PRODUCT_LOGOS_OPTIONS, generateImageUrl } from '@/utils/images-urls';
import { cn } from '@/utils/tailwind';
import { RedeemAgainBannerSkeleton } from './redeem-again-banner-skeleton';

type RedeemAgainBannerItemGtmProps = {
  event: 'homepage_v2_redeem_again_click';
  id_0: string;
  name_0: string;
  type_0: string;
  ranking: number;
};

export type ProductData = {
  productType: ProductType;
  productId: string;
  link: string;
};

const ProductNotAvailable = dynamic(
  () =>
    import('./product-not-available').then(
      (module) => module.ProductNotAvailable,
    ),
  {
    ssr: false,
  },
);

export function RedeemAgainBanner() {
  const t = useTranslations('redeemAgain');
  const router = useRouter();
  const {
    data: orderItemss,
    isLoading,
    isError,
  } = useRedeemAgainItems({
    pageSize: 3,
    pageNumber: 1,
  });

  const orderItems = orderItemss?.slice(0, 1);
  const redeemAgainText = t('redeemAgain');
  const viewAllRedemptionsText = t('viewAllRedemptions');

  const [clickedProductData, setClickedProductData] = useState<
    ProductData | undefined
  >();

  const { status } = useProduct(
    clickedProductData?.productId || '',
    clickedProductData?.productType || 'gift_card',
    !!clickedProductData?.productId,
  );

  useEffect(() => {
    if (status === 'success' && clickedProductData?.link) {
      router.push(clickedProductData.link);
    }
  }, [status, clickedProductData, router]);

  if (isLoading) {
    return <RedeemAgainBannerSkeleton />;
  }

  if (isError || !orderItems || orderItems.length === 0) {
    return null;
  }

  return (
    <>
      <ProductNotAvailable
        open={status === 'error'}
        onClose={() => {
          setClickedProductData(undefined);
        }}
      />
      <HomepageProductsCarousel
        titleComponent={
          <div className="flex w-full items-center justify-between gap-4 lg:justify-start">
            <p className="font-heading text-base-bold md:text-xl-bold">
              {redeemAgainText}
            </p>
            <RcLink
              href="/rewards-summary/my-rewards"
              variant={RcLinkVariant.SECONDARY}
              className="text-sm text-secondary lg:text-base"
            >
              {viewAllRedemptionsText}
            </RcLink>
          </div>
        }
        className="gap-6 container-responsive"
        carouselWrapperClassName="lg:grid lg:grid-cols-3 gap-6"
        hideOnNotSlidable
      >
        {orderItems.map((item, index) => {
          /**
           * NOTE(sontruong): redeemedProduct_productName
           * item.data.productName: string
           * no need to check nullish value for item.data.productName
           * since it can't be null/undefined
           */
          const redeemedProductText = t('redeemedProduct_productName', {
            productName: getProductName(item),
          });
          return (
            <button
              key={item.id}
              className={cn(
                'gtm:homepage_v2_redeem_again',
                'relative overflow-hidden',
                'flex w-[330px] shrink-0 items-center justify-start gap-4 lg:w-auto',
                'group rounded-custom bg-neutral-200 p-3 pr-5 transition-colors duration-200 hover:bg-neutral-300',
              )}
              onClick={() => {
                setClickedProductData(getProductData(item));
              }}
              data-gtm={JSON.stringify({
                event: 'homepage_v2_redeem_again_click',
                id_0: item.id,
                name_0: getProductName(item),
                type_0: item.type,
                ranking: index,
              } satisfies RedeemAgainBannerItemGtmProps)}
              aria-label={redeemedProductText}
            >
              <ImageWithFallback
                src={generateImageUrl({
                  url: item.data.productImageUrl,
                  ...PRODUCT_LOGOS_OPTIONS,
                })}
                className="h-12 rounded"
                alt=""
              />

              <p className="line-clamp-2 text-left text-sm font-bold lg:text-base">
                {getProductName(item)}
              </p>

              <Icon
                name="clock-rotate-left"
                className="absolute -right-2 ml-auto h-10 w-10 text-neutral-300 transition-colors duration-200 group-hover:text-neutral-400"
              />
            </button>
          );
        })}
      </HomepageProductsCarousel>
    </>
  );
}

function getProductLink(orderItem: RedeemAgainOrderItem) {
  // NN introduced a new field `originalCardId` referred to the original gift card id from listing endpoint
  // cardId is the denominationId FE used to checkout
  // NN can't backfill the originalCardId for old orders/redemptions so we need to handle both cases

  const productId =
    orderItem.type === 'gift_card_order_item'
      ? orderItem.data.originalCardId || orderItem.data.cardId
      : orderItem.data.loyaltyProgramId;

  return [
    'products',
    orderItemTypeToTypeInPath[orderItem.type],
    productId,
  ].join('/');
}

function getProductData(orderItem: RedeemAgainOrderItem) {
  const productId =
    orderItem.type === 'gift_card_order_item'
      ? orderItem.data.originalCardId || orderItem.data.cardId
      : orderItem.data.loyaltyProgramId;

  const productType = OrderItemTypeMapper[orderItem.type];
  return {
    productType,
    productId,
    link: getProductLink(orderItem),
  };
}

function getProductName(orderItem: RedeemAgainOrderItem) {
  return orderItem.type === 'gift_card_order_item'
    ? orderItem.data.originalProductName || orderItem.data.productName
    : orderItem.data.productName;
}
